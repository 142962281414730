<template>
  <div class="error-cen">
    <img class="img" :src="dataInfo.imgsSrc" alt="" />
    <p class="text">{{ dataInfo.text }}</p>
    <a src="javascript:;" class="btn" @click="back" v-if="!dataInfo.show"
      >返回 {{ second }}S</a
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      assets: {
        200: {
          imgsSrc: require("../../assets/image/200.png"),
          text: "您的自主入职表已成功提交给HR～",
          show: true,
        },
        204: {
          imgsSrc: require("../../assets/image/204.png"),
          text: "您的自主入职表提交失败，请重新提交～",
        },
        500: {
          imgsSrc: require("../../assets/image/500.png"),
          text: "服务器失联，请重新尝试～",
        },
        400: {
          imgsSrc: require("../../assets/image/400.png"),
          text: "网络连接失败，请重新尝试～",
        },
        600: {
          imgsSrc: require("../../assets/image/204.png"),
          text: "该链接无效",
          show: true,
        },
      },
      dataInfo: {},
      second: 10,
      timer: "",
    };
  },
  created() {
    this.dataInfo = this.assets[this.$route.query.type] || this.assets["400"];
    if (!this.dataInfo.show) {
      this.countDown();
    }
  },
  mounted() {},
  methods: {
    countDown() {
      //倒计时
      this.timer = setInterval(() => {
        if (this.second <= 0) {
          this.back();
          return;
        }
        this.second--;
      }, 1000);
    },
    back() {
      //返回路由
      clearInterval(this.timer);
      // this.$router.back(-1)
      this.$router.push({ path: "/", query: { ...this.$route.query } });
    },
  },
};
</script>

<style lang="scss" scoped>
.error-cen {
  text-align: center;
  overflow: hidden;
  .img {
    display: block;
    margin: 0 auto;
    margin-top: 87px;
  }
  .text {
    width: 100%;
    font-weight: 400;
    color: #141614;
    line-height: 21px;
    font-size: 15px;
    margin: 21px auto 44px auto;
  }
  .btn {
    width: 180px;
    line-height: 36px;
    background: #2e98ff;
    border-radius: 6px;
    color: #fff;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    display: block;
    margin: 0 auto;
  }
}
</style>
